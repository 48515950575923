var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("CupertinoAlertDialog", {
    attrs: {
      title: _vm.title,
      close: _vm.onClickOutSide,
      width: _vm.width,
      fontSize: _vm.fontSize,
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "px-2 py-2" }, [
              _c("div", { staticClass: "font-bold" }, [
                _vm._v(
                  " 原稿が入った作品フォルダを選択すると、Nolaに作品とその原稿をまとめてインポートできます。フォルダ名が作品名、ファイル名が原稿タイトルになります。 "
                ),
              ]),
              _c("img", {
                staticClass: "py-2",
                attrs: {
                  src: require("@/assets/img/features/file-import.png"),
                  alt: "file import",
                  width: "100%",
                },
              }),
              _c("div", { staticClass: "text-gray" }, [
                _vm._v(
                  " ※ 原稿ファイルは、テキストファイル（.txt）または、Wordファイル（.docx）のみアップロード可能です。 "
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "actions",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "flex flex-row flex-center flex-spacearound" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "w-full flex flex-center py-1 bottom-radius-10 font-bold",
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "text-white bg-blue px-2 py-1 rounded font-bold border-none hover pointer",
                        on: { click: _vm.onClickImport },
                      },
                      [_vm._v(" ファイルを選択する ")]
                    ),
                  ]
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }