
import Vue from "vue";
import { VueLoading } from "vue-loading-template";
import EventHeader from "@/components/organisms/EventHeader.vue";
import ContestCard from "@/components/molecules/ContestCard.vue";
import axiosBase from "axios";
import { showNotifyDialog } from "@/lib/dialog";
import orderBy from "lodash/orderBy";

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_MICROCMS_API_KEY },
});

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { VueLoading, EventHeader, ContestCard },
  data() {
    return {
      isLoading: true,
      contests: [],
    };
  },
  async created() {
    const contestRequestUrl = "/contest?limit=1000";
    const contestRequest = axios.get(contestRequestUrl);

    const result = await Promise.all([contestRequest]);

    const contest = result.find((x) => x.config.url === contestRequestUrl)!;

    if (contest.status !== 200) {
      await showNotifyDialog({
        title: "エラー",
        content: "コンテスト情報の取得に失敗しました。",
      });
    }

    this.contests = contest.data.contents;
    this.isLoading = false;
  },
  methods: {
    // コンテストの締切日までの残り日数を計算 (締切日が過ぎている場合は-1を返す)
    calculateDaysUntilDeadline(deadline) {
      if (!deadline) {
        return -1;
      }
      const now = new Date();
      const deadlineDateTime = new Date(deadline);
      // ミリ秒単位の差を日数に変換
      const inDays = Math.floor((deadlineDateTime.getTime() - +now) / (1000 * 60 * 60 * 24));
      // 同日内だが既に時間が過ぎている場合
      if (inDays === 0 && now > deadlineDateTime) {
        return -1;
      }
      return inDays;
    },
  },
  computed: {
    // 締切までの日数が 0 以上の募集中コンテスト（昇順：締切が近い順）
    availableContests() {
      const available = this.contests.filter((contest: any) => this.calculateDaysUntilDeadline(contest.deadline) >= 0);
      return orderBy(available, [(contest) => new Date(contest.deadline).getTime()], ["asc"]);
    },
    // 締切が過ぎたコンテスト（降順：最近締切のものが先頭）
    closedContests() {
      const closed = this.contests.filter((contest: any) => this.calculateDaysUntilDeadline(contest.deadline) < 0);
      return orderBy(closed, [(contest) => new Date(contest.deadline).getTime()], ["desc"]);
    },
    // 表示用に募集中コンテストの後に終了コンテストを連結
    sortedContests() {
      return [...this.availableContests, ...this.closedContests];
    },
  },
});

interface Props {}

interface Data {
  isLoading: boolean;
  contests: Contest[];
}

interface Computed {
  availableContests: Contest[];
  closedContests: Contest[];
  sortedContests: Contest[];
}

interface Methods {
  calculateDaysUntilDeadline: (deadline: string) => number;
}

type Contest = {
  [key: string]: any;
};
