var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "div",
        { staticClass: "side" },
        [
          _vm.isPC
            ? _c("contest-notification", {
                staticClass: "side-content",
                attrs: { contests: _vm.contests },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "analytics" },
            [
              _c("writing-data", { staticClass: "side-content" }),
              _vm.isConnectedNolaNovel
                ? _c("nola-novel-data", { staticClass: "side-content" })
                : _vm._e(),
            ],
            1
          ),
          _c("banner-list", {
            staticClass: "side-content",
            attrs: { banner: _vm.banner },
          }),
        ],
        1
      ),
      _c("novel-list", {
        key: "novel-list",
        ref: "novelList",
        staticClass: "novel-list",
        on: {
          changeLoading: _vm.changeLoading,
          "import-end": _vm.importEnd,
          "import-end-outside": _vm.importEndOutside,
        },
      }),
      _vm.isLoading
        ? _c("MaskedLoading", { staticClass: "loading" })
        : _vm._e(),
      _vm.showFirstOnboarding
        ? _c("Onboarding", {
            attrs: {
              targetSelector: ".tutorial-novel",
              message: `<div style='font-size:16px;margin-bottom:16px;'>ご登録くださり誠にありがとうございます。<br>Nolaでどんなことができるのか、テストデータを用意しましたので、まずは<span style='color:#F1B700;'>この作品をクリック</span>して下さい。</div>`,
              closeText: "チュートリアルを閉じる",
              closeTextColor: "#9A9A9A",
              canOutsideClick: true,
              tooltipPosition: "right-top",
              maxWidth: "460px",
              tooltipPadding: "14px 20px",
              tooltipBorderRadius: "15px",
            },
            on: {
              "onboarding-finished": _vm.handleOnboardingFirstFinished,
              "onboarding-close-clicked": _vm.onboardingFirstCloseClicked,
              "onboarding-target-clicked": _vm.onboardingFirstTargetClicked,
              "onboarding-outside-clicked": _vm.onboardingFirstOutsideClicked,
            },
          })
        : _vm._e(),
      _vm.showImportTutorial
        ? _c("ImportTutorialDialog", {
            attrs: {
              close: () => {
                _vm.showImportTutorial = false
              },
            },
            on: {
              "onboarding-import-clicked": _vm.onboardingImportClicked,
              "onboarding-skip-clicked": _vm.onboardingSkipClicked,
            },
          })
        : _vm._e(),
      _vm.showImportOnboarding
        ? _c("FirstImportOnboarding", {
            attrs: { currentStepIndex: _vm.tutorialStepIndex },
            on: {
              "tutorial-target-clicked": function ($event) {
                return _vm.onImportTutorialTargetClicked($event)
              },
              "add-currentStepIndex": function ($event) {
                _vm.tutorialStepIndex += 1
              },
              "tutorial-completed": _vm.onImportTutorialCompleted,
              "outside-clicked": _vm.onImportTutorialOutsideClicked,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }