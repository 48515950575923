var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "root-novel-list" },
    [
      _c("div", { staticClass: "novel-list-header" }, [
        _c("h2", [_vm._v("作品一覧")]),
        _c(
          "div",
          { staticClass: "pc flex flex-center" },
          [
            _c(
              "div",
              {
                staticClass: "flex novel-import",
                on: {
                  click: function ($event) {
                    return _vm.onClickImportFile(false)
                  },
                },
              },
              [
                _c("FileImportOutlineIcon", {
                  staticClass: "flex flex-center mr-5",
                }),
                _c("span", [_vm._v("作品のインポート")]),
              ],
              1
            ),
            _c("memo-button"),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "status" },
        [
          _vm._l(_vm.workStatusList, function (status) {
            return [
              _c(
                "span",
                {
                  key: status.id,
                  class: { active: _vm.workStatus.id === status.id },
                  on: {
                    click: function ($event) {
                      return _vm.selectWorkStatus(status)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(status.name) + " ")]
              ),
            ]
          }),
        ],
        2
      ),
      _c("div", { staticClass: "filter" }, [
        _c("div", { staticClass: "attributes-container" }, [
          _c(
            "div",
            { staticClass: "filter-item genre" },
            [
              _c("span", { staticClass: "title" }, [_vm._v("ジャンル")]),
              _c("select-box", {
                staticClass: "selectbox",
                attrs: {
                  items: _vm.genreList,
                  keyAttr: "id",
                  placeholder: "ジャンルで絞り込み",
                  selected: _vm.genre,
                  enableUnselected: "",
                  grayColor: "",
                },
                on: { select: _vm.selectGenre, unselect: _vm.unselectGenre },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "filter-item category" },
            [
              _c("span", { staticClass: "title" }, [_vm._v("カテゴリ")]),
              _c("select-box", {
                staticClass: "selectbox",
                attrs: {
                  items: _vm.categoryList,
                  keyAttr: "id",
                  placeholder: "カテゴリで絞り込み",
                  selected: _vm.category,
                  enableUnselected: "",
                  grayColor: "",
                },
                on: {
                  select: _vm.selectCategory,
                  unselect: _vm.unselectCategory,
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "filter-item sort" },
          [
            _c("select-box", {
              staticClass: "selectbox sort",
              attrs: {
                items: _vm.sortList,
                keyAttr: "id",
                placeholder: "指定なし",
                selected: _vm.sort,
                enableUnselected: "",
              },
              on: { select: _vm.selectSort, unselect: _vm.unselectSort },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "list-container" },
        [
          _vm.novels
            ? _c(
                "div",
                { staticClass: "list" },
                [
                  _vm._l(_vm.novels, function (novel) {
                    return [
                      _c("novel-card", {
                        key: novel.novelId,
                        staticClass: "novel-item",
                        attrs: {
                          novel: novel,
                          "is-menu-open": _vm.openMenuId === novel.novelId,
                        },
                        on: {
                          "toggle-menu-click": _vm.handleToggleMenu,
                          changeLoading: _vm.changeLoading,
                        },
                      }),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
          _c(
            "button-round",
            {
              staticClass: "novel-create tutorial-novel-create",
              attrs: {
                to: { name: "novelCreate" },
                icon: require("@/assets/img/icon/add_book.svg"),
                size: 40,
              },
            },
            [
              _c("div", { staticClass: "pc remove-margin" }),
              _c("span", { staticClass: "sp" }, [
                _vm._v("新しい作品を登録する"),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("input", {
        ref: "input",
        staticStyle: { display: "none" },
        attrs: { type: "file", webkitdirectory: "" },
        on: {
          change: function ($event) {
            return _vm.onSelectFile()
          },
        },
      }),
      _vm.isLoading
        ? _c("masked-loading", { staticClass: "loading" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }