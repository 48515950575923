
import Vue from "vue";
import ExpandedItem from "@/components/atoms/ExpandedItem.vue";

interface Data {
  isOpen: boolean;
  isCurrent: boolean;
  currentName: string;
  isMouseHover: boolean;
}

interface Methods {
  onClick: () => void;
  onLinkClick: (item: any) => void;
  isActive: (item: any) => boolean;
  onMouseOver: () => void;
  onMouseLeave: () => void;
}

interface Computed {
  isSubPlot: boolean;
}

interface Props {
  link: string;
  text: string;
  icon: string;
  novelId: string;
  expand: boolean;
  children: any[];
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ExpandedItem,
  },
  data() {
    return {
      isOpen: true,
      isCurrent: this.$route.name === this.link,
      currentName: "",
      isMouseHover: false,
    };
  },
  created() {},
  props: {
    link: String,
    text: String,
    icon: String,
    novelId: String,
    expand: {
      type: Boolean,
      default: true,
    },
    children: Array,
  },
  computed: {
    isSubPlot() {
      return !!this.$route.params.plotId;
    },
  },
  methods: {
    onClick() {
      this.isOpen = !this.isOpen;
    },
    onLinkClick(item) {
      const { novelId, link } = this;
      const linkName = item ? item.link : link;

      // サブプロットの場合は、常に遷移させる
      if (this.isSubPlot) {
        this.$router.push({ name: linkName, params: { novelId } });
        return;
      }

      // 現在のルートと遷移先が同じなら処理をスキップ
      if (this.$route.name === linkName && this.$route.params.novelId === novelId) {
        return;
      }

      this.$router.push({ name: linkName, params: { novelId } });
    },
    isActive(item: any) {
      const { link } = item;
      const { name } = this.$route;
      return name === link;
    },
    onMouseOver() {
      this.isMouseHover = true;
    },
    onMouseLeave() {
      this.isMouseHover = false;
    },
  },
});
