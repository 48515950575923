import { initializeApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig, getValue, RemoteConfig } from 'firebase/remote-config';
import { remoteConfigParams } from './remoteConfigParams';

export class FirebaseRemoteConfigManager {
  private static instance: FirebaseRemoteConfigManager;

  private remoteConfig: RemoteConfig;

  // Singleton 外部からオブジェクト生成されないようにPrivateコンストラクタとする
  private constructor() {
    const firebaseConfig = {
      apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
      authDomain: process.env.VUE_APP_AUTH_DOMAIN,
      databaseURL: process.env.VUE_APP_DATABSE_URL,
      projectId: process.env.VUE_APP_PROJECT_ID,
      storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
      appId: process.env.VUE_APP_APP_ID,
      measurementId: process.env.VUE_APP_MEASUREMENT_ID
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    // Remote Configの初期化
    this.remoteConfig = getRemoteConfig(app);
    this.remoteConfig.settings = {
      // デフォルトでは1分(60000ms)
      fetchTimeoutMillis: 60000,
      // デフォルトは12時間(43200000ms)、開発中はすぐに反映されるように1分等(60000ms)に設定
      minimumFetchIntervalMillis: 43200000,
    };
  }

  /**
   * Singletonのためのインスタンスゲッター
   * @returns FirebaseRemoteConfigManagerのインスタンス
   */
  public static getInstance(): FirebaseRemoteConfigManager {
    if (!this.instance) {
      this.instance = new FirebaseRemoteConfigManager();
    }

    return FirebaseRemoteConfigManager.instance;
  }

  /**
   * Remote Configの値を取得する
   * @param {string[]} keys 取得したいキーの配列
   * @returns {Record<string, any>} 取得したキーと値のペア
   */
  public async getRemoteConfigValue(keys: string[]): Promise<Record<string, any>> {
    const fetchedKeyValues: Record<string, string> = {};

    try {
      // fetchAndActivate fetch:サーバーからの最新の設定を取得 / active:fetchで取得した値をアプリ側で有効にする
      // ただし、minimumFetchIntervalMillisで設定した時間間隔内はfetchが実行されず、キャッシュされた値が返される
      await fetchAndActivate(this.remoteConfig);

      // 必要なキーだけを抽出
      keys.forEach((key) => {
        fetchedKeyValues[key] = getValue(this.remoteConfig, key).asString();
      });

    } catch {
      // 問題が発生した場合はremoteConfigParamsで設定しているデフォルト値を返す
      keys.forEach((key) => {
        fetchedKeyValues[key] = remoteConfigParams[key] || '';
      });
    }

    return fetchedKeyValues;
  }

}