var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "banner-container" }, [
      _c("img", {
        staticClass: "banner",
        attrs: { src: _vm.bannerImg, alt: "ogp" },
      }),
    ]),
    _c("div", { staticClass: "text-container" }, [
      _c("div", [
        _c("div", { staticClass: "name-container" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: _vm.iconImg, alt: "icon", width: "40", height: "40" },
          }),
          _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
        ]),
      ]),
      _c("div", [
        _c("div", { staticClass: "comment-container" }, [
          _c("div", { staticClass: "comment" }, [_vm._v(_vm._s(_vm.comment))]),
        ]),
        _c("div", { staticClass: "match-container" }, [
          _c("div", { staticClass: "match-label" }, [
            _vm.numOfMatchedNovels > 0
              ? _c("div", [
                  _vm._v(" マッチした作品"),
                  _c("span", { staticClass: "match-count" }, [
                    _vm._v(_vm._s(_vm.numOfMatchedNovels)),
                  ]),
                  _vm._v("作品 "),
                ])
              : _c("div", [_vm._v(" マッチしている作品を投稿しよう！ ")]),
          ]),
        ]),
        _c("div", { staticClass: "button-container" }, [
          _c(
            "button",
            {
              staticClass: "button-common-style detail-button",
              on: { click: _vm.onClickOpenDetail },
            },
            [_vm._v(" 詳しく見る ")]
          ),
          _c(
            "button",
            {
              staticClass: "button-common-style post-button",
              on: { click: _vm.onClickPost },
            },
            [_vm._v(" 投稿する ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }