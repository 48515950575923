
import Vue, { PropType } from "vue";
import { format } from "date-fns";
import NolaNovelListItem from "@/components/molecules/NolaNovelListItem.vue";
import { Manuscript, Novel } from "@/lib/models";
import { showNotifyDialog } from "@/lib/dialog";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { NolaNovelListItem },
  props: {
    campaignId: String,
    novel: Object as PropType<Novel>,
    selectedNovelIds: Array as PropType<string[]>,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    updatedAt() {
      const { updatedAtLatestDataInNovel, updatedAt } = this.novel;
      return format(updatedAtLatestDataInNovel || updatedAt!, "YYYY/MM/DD");
    },
    createdAt() {
      const { createdAt } = this.novel;
      return format(createdAt!, "YYYY/MM/DD");
    },
    manuscripts() {
      return this.$store.getters["manuscriptModule/manuscriptList"];
    },
    isAlreadyApplied() {
      return this.novel.agentCampaignIds?.includes(this.campaignId) ?? false;
    },
  },
  methods: {
    async selectNovel() {
      this.loading = true;

      // 作品選択時に原稿が存在するかのチェックを行う
      await this.$store.dispatch("manuscriptModule/initialize", {
        novelId: this.novel.novelId,
      });

      if (this.manuscripts.length === 0) {
        await showNotifyDialog({
          title: "エラー",
          content: "原稿がない作品はエージェントに提出できません。",
        });
      } else {
        this.$emit("input", [...this.selectedNovelIds, this.novel.novelId]);
      }

      this.loading = false;
    },
    unselectNovel() {
      this.$emit(
        "input",
        this.selectedNovelIds.filter((id: string) => id !== this.novel.novelId)
      );
    },
  },
});

interface Props {
  campaignId: string;
  novel: Novel;
  selectedNovelIds: string[];
}

interface Data {
  loading: boolean;
}

interface Computed {
  updatedAt: string;
  createdAt: string;
  manuscripts: Manuscript[];
  /**
   * 作品にエージェントCPのキャンペーンIDが含まれているかで応募済みかを判定する
   */
  isAlreadyApplied: boolean;
}

interface Methods {
  selectNovel(): void;
  unselectNovel(): void;
}
