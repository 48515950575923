
import Vue from "vue";
import { format } from "date-fns";
import BookmarkIcon from "icons/Bookmark.vue";
import AccountIcon from "icons/Account.vue";
import EmailOutlineIcon from "icons/EmailOutline.vue";
import BullhornIcon from "icons/Bullhorn.vue";
import CommentTextOutlineIcon from "icons/CommentTextOutline.vue";
import ShoePrintIcon from "icons/ShoePrint.vue";
import ThumbUpIcon from "icons/ThumbUp.vue";
import ChevronRightIcon from "icons/ChevronRight.vue";
import FlagVariantIcon from "icons/FlagVariant.vue";
import { NotificationDataType, NotificationFromNolaNovel } from "@/lib/models";
import { createUrlWithUtmParams } from "@/lib/utils/url";
import { NolaItemId, NolaPageName } from "@/lib/utils/analytics";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    BookmarkIcon,
    AccountIcon,
    EmailOutlineIcon,
    BullhornIcon,
    CommentTextOutlineIcon,
    ShoePrintIcon,
    ThumbUpIcon,
    ChevronRightIcon,
    FlagVariantIcon,
  },
  props: {
    notification: {},
  },
  computed: {
    iconType() {
      switch (this.notification.type) {
        case NotificationDataType.NOVEL_UPDATED:
        case NotificationDataType.BOOKMARK:
          return "Bookmark";
        case NotificationDataType.COMPANY_BOOKMARK:
          return "FlagVariant";
        case NotificationDataType.FOLLOW_NEWNOVEL:
        case NotificationDataType.FOLLOW_NEWEPISODE:
          return "Account";
        case NotificationDataType.REVIEW:
          return "EmailOutline";
        case NotificationDataType.FOLLOW:
        case NotificationDataType.FOLLOW_NEWACTIVITY:
        case NotificationDataType.COMPANY_FEATURED_NOVEL:
        case NotificationDataType.ADMIN_PICKUP:
          return "Bullhorn";
        case NotificationDataType.COMMENT:
        case NotificationDataType.COMMENT_REPLY:
        case NotificationDataType.COMMENT_ACTIVITY_REPLY:
        case NotificationDataType.COMMENT_ACTIVITY:
          return "CommentTextOutline";
        case NotificationDataType.FAVORITE_ACTIVITY:
          return "ThumbUp";
        case NotificationDataType.COMPANY_FOOTPRINTS:
        case NotificationDataType.COMPANY_FOOTPRINTS_EPISODE:
          return "ShoePrint";
        default:
          return "Bullhorn";
      }
    },
    iconColor() {
      switch (this.notification.type) {
        case NotificationDataType.NOVEL_UPDATED:
        case NotificationDataType.FOLLOW_NEWNOVEL:
        case NotificationDataType.FOLLOW_NEWEPISODE:
        case NotificationDataType.FOLLOW_NEWACTIVITY:
          return "#9BC9FF";
        case NotificationDataType.BOOKMARK:
        case NotificationDataType.REVIEW:
        case NotificationDataType.FOLLOW:
        case NotificationDataType.COMMENT:
        case NotificationDataType.COMMENT_REPLY:
        case NotificationDataType.COMMENT_ACTIVITY:
        case NotificationDataType.COMMENT_ACTIVITY_REPLY:
        case NotificationDataType.FAVORITE_ACTIVITY:
          return "#FAD79D";
        case NotificationDataType.COMPANY_BOOKMARK:
        case NotificationDataType.COMPANY_FOOTPRINTS:
        case NotificationDataType.COMPANY_FOOTPRINTS_EPISODE:
        case NotificationDataType.COMPANY_FEATURED_NOVEL:
        case NotificationDataType.ADMIN_PICKUP:
          return "#FFFFFF";
        default:
          return "#FAD79D";
      }
    },
    bgColor() {
      switch (this.notification.type) {
        case NotificationDataType.NOVEL_UPDATED:
        case NotificationDataType.FOLLOW_NEWNOVEL:
        case NotificationDataType.FOLLOW_NEWEPISODE:
        case NotificationDataType.FOLLOW_NEWACTIVITY:
          return "blue";
        case NotificationDataType.BOOKMARK:
        case NotificationDataType.REVIEW:
        case NotificationDataType.FOLLOW:
        case NotificationDataType.COMMENT:
        case NotificationDataType.COMMENT_REPLY:
        case NotificationDataType.COMMENT_ACTIVITY:
        case NotificationDataType.COMMENT_ACTIVITY_REPLY:
        case NotificationDataType.FAVORITE_ACTIVITY:
          return "orange";
        case NotificationDataType.COMPANY_BOOKMARK:
        case NotificationDataType.COMPANY_FOOTPRINTS:
        case NotificationDataType.COMPANY_FOOTPRINTS_EPISODE:
        case NotificationDataType.COMPANY_FEATURED_NOVEL:
        case NotificationDataType.ADMIN_PICKUP:
          return "gold";
        default:
          return "orange";
      }
    },
    text() {
      const { type, novel, user, episode, company } = this.notification;

      switch (type) {
        case NotificationDataType.NOVEL_UPDATED:
          return `ブックマークしている${novel!.title}が更新されました。`;
        case NotificationDataType.FOLLOW_NEWNOVEL:
          return `フォローしている${user!.name}さんの新作小説が投稿されました。`;
        case NotificationDataType.FOLLOW_NEWEPISODE:
          return `フォローしている${user!.name}さんの新作エピソードが投稿されました。`;
        case NotificationDataType.BOOKMARK:
          return `${novel!.title}がブックマークされました！`;
        case NotificationDataType.REVIEW:
          return `${novel!.title}に感想が届きました！`;
        case NotificationDataType.FOLLOW:
          return `${user!.name}さんにフォローされました！`;
        case NotificationDataType.COMMENT:
          return `エピソード「${episode!.title}」にコメントが届きました！`;
        case NotificationDataType.COMMENT_REPLY:
          return `エピソード「${episode!.title}」へのコメントに返信が届きました！`;
        case NotificationDataType.FOLLOW_NEWACTIVITY:
          return `フォローしている${user!.name}さんの活動報告が更新されました！`;
        case NotificationDataType.COMMENT_ACTIVITY:
          return `活動報告にコメントが届きました！`;
        case NotificationDataType.COMMENT_ACTIVITY_REPLY:
          return `あなたの活動報告のコメントに返信が届きました`;
        case NotificationDataType.FAVORITE_ACTIVITY:
          return `活動報告にいいね！がつきました！`;
        case NotificationDataType.COMPANY_FOOTPRINTS:
          return `「${novel!.title}」の作品ページに${company!.name}の足あとがつきました！`;
        case NotificationDataType.COMPANY_FOOTPRINTS_EPISODE:
          return `「${novel!.title}」のエピソード「${episode!.title}」に${company!.name}の足あとがつきました！`;
        case NotificationDataType.COMPANY_BOOKMARK:
          return `「${novel!.title}」に${company!.name}の注目フラグが立ちました！`;
        case NotificationDataType.COMPANY_FEATURED_NOVEL:
          return `「${novel!.title}」が${company!.name}の注目作品に掲載されました！`;
        case NotificationDataType.ADMIN_PICKUP:
          return `「${novel!.title}」が運営のピックアップに掲載されました！`;
        default:
          return "通知があります。";
      }
    },
    formatDatetime() {
      const date = new Date(this.notification.publishedAt);
      return format(date, "YYYY/MM/DD HH:mm");
    },
    isRead() {
      return this.notification.isRead;
    },
    path() {
      const { type, novel, user, episode, comment, activity, company } = this.notification;

      switch (type) {
        case NotificationDataType.NOVEL_UPDATED:
        case NotificationDataType.FOLLOW_NEWNOVEL:
        case NotificationDataType.FOLLOW_NEWEPISODE:
        case NotificationDataType.BOOKMARK:
        case NotificationDataType.REVIEW:
          return `/novel/${novel!.id}`;
        case NotificationDataType.FOLLOW:
          return `/user/${user!.id}`;
        case NotificationDataType.COMMENT:
        case NotificationDataType.COMMENT_REPLY:
          return `/comments/${episode!.id}?id=${comment!.id}`;
        case NotificationDataType.FAVORITE_ACTIVITY:
          return `/activity/${activity!.id}`;
        case NotificationDataType.COMMENT_ACTIVITY:
          return `/activity/${activity!.id}?id=${comment!.id}`;
        case NotificationDataType.COMMENT_ACTIVITY_REPLY:
          return `/activity/${activity!.id}?id=${comment!.id}`;
        case NotificationDataType.FOLLOW_NEWACTIVITY:
          return `/activity/${activity!.id}`;
        case NotificationDataType.COMPANY_BOOKMARK:
        case NotificationDataType.COMPANY_FOOTPRINTS:
        case NotificationDataType.COMPANY_FOOTPRINTS_EPISODE:
          return `/company/${company!.id}`;
        case NotificationDataType.COMPANY_FEATURED_NOVEL:
          return `/?featuredCompany=${company!.id}`;
        case NotificationDataType.ADMIN_PICKUP:
          return "/?id=adminPickup";
        default:
          return "/notification";
      }
    },
  },
  methods: {
    onClick() {
      const { isRead, typeTargetIdSubTargetId } = this.notification;

      window.open(
        createUrlWithUtmParams(
          `${process.env.VUE_APP_NOLANOVEL_WEB}${this.path}`,
          NolaPageName.NolaNovelNotification,
          NolaItemId.NotificationItem
        ),
        process.env.VUE_APP_NOLANOVEL_NAME
      );
      if (!isRead) {
        this.$store.dispatch("nolaNovelModule/readNotification", typeTargetIdSubTargetId);
      }
    },
  },
});

interface Props {
  notification: NotificationFromNolaNovel;
}

interface Data {}

interface Computed {
  iconType: string;
  iconColor: string;
  bgColor: string;
  text: string;
  formatDatetime: string;
  isRead: boolean;
  path: string;
}

interface Methods {
  onClick(): void;
}
