var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "mask overlay-fade" }, [
    _c("div", { staticClass: "bg-white flex flex-column tutorial-frame" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/img/tutorial/import_tutorial_back.png"),
          alt: "import_tutorial",
        },
      }),
      _c("div", [
        _c("div", { staticClass: "title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.isNovelCreated
                  ? "お手元の作品をインポートできます"
                  : "さっそく執筆をはじめましょう"
              ) +
              " "
          ),
        ]),
        _c("div", { staticClass: "content" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.isNovelCreated
                  ? "Nolaでは、"
                  : "チュートリアルお疲れ様でした。 執筆を開始するにあたり、"
              )
          ),
          _c("span", { staticClass: "yellow" }, [
            _vm._v("お手元の作品ファイルをNolaにインポートすることが可能"),
          ]),
          _vm._v(
            "です。書きかけの作品などを下記のボタンからぜひインポートしてみてください🐈‍⬛📚 "
          ),
        ]),
        _c("div", { staticClass: "import" }, [
          _c(
            "button",
            { staticClass: "import-button", on: { click: _vm.importClicked } },
            [_vm._v("作品をインポートする")]
          ),
        ]),
        _c("div", { staticClass: "skip", on: { click: _vm.skipClicked } }, [
          _vm._v("スキップ"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }