var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "card" }, [
    _c("div", { staticClass: "banner-container" }, [
      _c("img", {
        staticClass: "banner",
        attrs: { src: _vm.bannerImg, alt: "ogp" },
      }),
    ]),
    _c("div", { staticClass: "text-container" }, [
      _c("div", [
        _c("div", { staticClass: "name-container" }, [
          _c("img", {
            staticClass: "icon",
            attrs: { src: _vm.iconImg, alt: "icon", width: "40", height: "40" },
          }),
          _c("div", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
        ]),
        _c("div", { staticClass: "comment" }, [_vm._v(_vm._s(_vm.comment))]),
      ]),
      _c("div", [
        _c("div", { staticClass: "match-container" }, [
          _vm._m(0),
          _c("div", { staticClass: "match-description" }, [
            _vm._v(" 全" + _vm._s(_vm.numOfNovels) + "作品中"),
            _c("span", { staticClass: "match-count" }, [
              _vm._v(_vm._s(_vm.numOfMatchedNovels)),
            ]),
            _vm._v("作品 "),
          ]),
        ]),
        _c("div", { staticClass: "button-container" }, [
          _c(
            "button",
            {
              staticClass: "button-common-style detail-button",
              on: { click: _vm.onClickOpenDetail },
            },
            [_vm._v(" 詳しく見る ")]
          ),
          _c(
            "button",
            {
              staticClass: "button-common-style post-button",
              on: { click: _vm.onClickPost },
            },
            [_vm._v(" 作品を持ち込み投稿する ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "match-label" }, [
      _vm._v(" 編集者の好みとマッチしている"),
      _c("br"),
      _vm._v("あなたの作品 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }