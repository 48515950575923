
import Vue from "vue";
import ImageIcon from "@/components/atoms/ImageIcon.vue";
import EmailOutlineIcon from "@/components/atoms/EmailOutlineIcon.vue";
import { News } from "@/lib/models/general";
import { NotificationsFromNolaNovel } from "@/lib/models";

export default Vue.extend<{}, Methods, Computed, {}>({
  components: { ImageIcon, EmailOutlineIcon },
  computed: {
    isActivePath() {
      return (name, excludePath) => {
        const { path } = this.$route;
        if (excludePath && path.startsWith(excludePath)) {
          return false;
        }
        return path.startsWith(name);
      };
    },
    isUnread() {
      const { latestNews, readLatestNews } = this;
      if (latestNews && readLatestNews) {
        return latestNews.updatedAt !== readLatestNews.updatedAt;
      }
      return true;
    },
    latestNews() {
      return this.$store.getters["generalModule/latestNews"];
    },
    readLatestNews() {
      return this.$store.getters["generalModule/readLatestNews"];
    },
    isUnreadFromNolaNovel() {
      const unreadNotifications = this.$store.getters["nolaNovelModule/notifications"](
        false
      ) as NotificationsFromNolaNovel;
      return unreadNotifications.items.length > 0;
    },
    hasUnreadAgentNotifications() {
      const notificationSummary = this.$store.getters["notificationModule/notificationSummary"];
      return notificationSummary?.unreadItemCount > 0;
    },
  },
  methods: {
    onClickLink(name) {
      this.$gtm.trackEvent({
        event: "click_header_link",
        value: name,
      });
    },
  },
});

interface Computed {
  isActivePath(name: string, excludePath?: string): boolean;
  isUnread: boolean;
  latestNews: News;
  readLatestNews: News;
  isUnreadFromNolaNovel: boolean;
  hasUnreadAgentNotifications: boolean;
}

interface Methods {
  onClickLink(name: string): void;
}
