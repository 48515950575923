var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isCreating
    ? _c(
        "div",
        { staticClass: "h-full bg-gray flex-1 flex flex-center" },
        [
          _c("vue-loading", {
            attrs: {
              type: "spiningDubbles",
              color: "#efad4c",
              size: { width: "100px", height: "100px" },
            },
          }),
        ],
        1
      )
    : _c(
        "div",
        {
          staticClass:
            "h-full flex-1 bg-gray scroll-y flex flex-column align-items",
        },
        [
          _c("h2", { staticClass: "mx-1" }, [
            _vm._v("新しい作品を作成しましょう"),
          ]),
          _c("div", { staticClass: "card flex flex-column" }, [
            _c("div", { staticClass: "px-1 flex flex-column flex-center" }, [
              _vm._m(0),
              _vm._m(1),
              _c("div", { staticClass: "input-text flex flex-center" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.title,
                      expression: "title",
                    },
                  ],
                  staticClass:
                    "flex-1 mx-1 my-1 px-1/2 py-1/2 placeholder-center",
                  attrs: {
                    type: "text",
                    placeholder: "作品のタイトルを入力してください",
                    autofocus: "",
                    maxlength: "80",
                  },
                  domProps: { value: _vm.title },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.title = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _c("hr", { staticClass: "dotted light-gray w-full" }),
            _c("div", { staticClass: "px-1 flex flex-column flex-center" }, [
              _vm._m(2),
              _c("span", { staticClass: "text-center" }, [
                _vm._v(
                  " 執筆予定の作品のジャンルとカテゴリを選択してください。 "
                ),
              ]),
              _c(
                "div",
                { staticClass: "flex flex-column flex-center w-full" },
                [
                  _c(
                    "div",
                    { staticClass: "input-text flex flex-center" },
                    [
                      _c("select-box", {
                        staticClass: "flex-1 mx-1 my-1",
                        attrs: {
                          items: _vm.genreList,
                          keyAttr: "id",
                          placeholder: "ジャンルを選択してください",
                          selected: _vm.genre,
                          enableUnselected: "",
                        },
                        on: {
                          select: _vm.onSelectGenre,
                          unselect: _vm.onUnSelectGenre,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "input-text flex flex-center mx-1" },
                    [
                      _c("multiple-select-box", {
                        staticClass: "flex-1 mx-1 mb-1",
                        attrs: {
                          items: _vm.categoryList,
                          keyAttr: "id",
                          placeholder: "カテゴリを選択してください（３つまで）",
                          max: 3,
                          selected: _vm.categories,
                        },
                        on: { select: _vm.onSelectCategories },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("hr", { staticClass: "dotted light-gray w-full" }),
            _c("div", { staticClass: "px-1 flex flex-column flex-center" }, [
              _c("h3", [_vm._v("③ 執筆予定の文字数を入力してください")]),
              _vm._m(3),
              _c(
                "div",
                { staticClass: "flex flex-row flex-center gap-1 mx-1 my-1" },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.wordLength,
                        expression: "wordLength",
                      },
                    ],
                    staticClass: "px-1/2 py-1/2 placeholder-right text-right",
                    attrs: {
                      type: "text",
                      placeholder: "文字数",
                      maxlength: "18",
                    },
                    domProps: { value: _vm.wordLength },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.wordLength = $event.target.value
                      },
                    },
                  }),
                  _c("span", { staticClass: "bold" }, [_vm._v("文字予定")]),
                ]
              ),
              _c("span", [
                _vm._v(
                  " ※ 文字数の参考：単行本小説：20万〜30万字 / 短編小説：5000〜2万字 / ショートショート：〜5000字 "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "flex flex-center my-1 my-1" }, [
            _c(
              "button",
              {
                staticClass: "button px-4 pointer",
                attrs: { type: "submit", disabled: !_vm.canSubmit },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.onCreateButtonClick.apply(null, arguments)
                  },
                },
              },
              [_c("h4", { staticClass: "my-1/2" }, [_vm._v("執筆を開始する")])]
            ),
          ]),
          _c(
            "div",
            { staticClass: "skip-start", on: { click: _vm.onSkipStart } },
            [_vm._m(4), _c("div", { staticClass: "skip-underline" })]
          ),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h3", [
      _vm._v("① 作品のタイトルを入力してください"),
      _c("span", { staticClass: "red" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "text-center" }, [
      _vm._v(" 新しく作成する作品のタイトルを入力してください。"),
      _c("br"),
      _vm._v("なお、タイトルは後から変更が可能です。 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("h3", [
      _vm._v("② ジャンル・カテゴリを選択してください"),
      _c("span", { staticClass: "red" }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "text-center" }, [
      _vm._v(
        " 執筆予定の作品の規模感に合わせて目標文字数を入力してみましょう。"
      ),
      _c("br"),
      _vm._v(
        " 執筆の進捗を「予定文字数まであと○%」の表記で確認できるようになります。 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "skip-wrapper" }, [
      _c("span", [_vm._v("スキップして開始する")]),
      _c("span", { staticClass: "arrow" }, [_vm._v("＞")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }