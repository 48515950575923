var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("EventHeader"),
      _c(
        "div",
        { staticClass: "body" },
        [
          _vm.isLoading
            ? _c("vue-loading", {
                attrs: {
                  type: "spiningDubbles",
                  color: "#efad4c",
                  size: { width: "50px", height: "50px" },
                },
              })
            : [
                _c(
                  "div",
                  {
                    class: {
                      "flex-container": _vm.iframeCompanyUrl.length > 0,
                    },
                  },
                  [
                    _c("div", { staticClass: "card-area" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("編集部に作品を持ち込む"),
                      ]),
                      _c("div", { staticClass: "description" }, [
                        _vm._v(
                          " Nolaから利用できる投稿サイトNolaノベルの「編集部の掲示板」では、各社の編集部が探している作品像を公開しています。"
                        ),
                        _c("br"),
                        _vm._v(
                          "作品像を確認し投稿すると、編集部から足あとや感想、出版スカウトが届くことがあります。 "
                        ),
                      ]),
                      _vm.companies.length
                        ? _c(
                            "div",
                            { staticClass: "card-list" },
                            [
                              _vm._l(_vm.companies, function (company) {
                                return [
                                  _vm.companyPostButtonAbKey === "A"
                                    ? _c("CompanyCard", {
                                        key: company.id,
                                        ref: "companyCard",
                                        refInFor: true,
                                        attrs: {
                                          company: company,
                                          matchedNovelIds:
                                            _vm.companyMatchedNovelIds[
                                              company.id
                                            ],
                                        },
                                        on: {
                                          openDetailFrame: _vm.openDetailFrame,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm.companyPostButtonAbKey === "B"
                                    ? _c("CompanyCardVerB", {
                                        key: company.id,
                                        ref: "companyCard",
                                        refInFor: true,
                                        attrs: {
                                          company: company,
                                          matchedNovelIds:
                                            _vm.companyMatchedNovelIds[
                                              company.id
                                            ],
                                        },
                                        on: {
                                          openDetailFrame: _vm.openDetailFrame,
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              }),
                            ],
                            2
                          )
                        : _c("div", { staticClass: "empty-card" }, [
                            _vm._v("該当する編集部の掲示板がありません"),
                          ]),
                    ]),
                    _vm.iframeCompanyUrl.length > 0
                      ? _c("div", { staticClass: "iframe-area" })
                      : _vm._e(),
                  ]
                ),
              ],
          _vm.iframeCompanyUrl.length > 0
            ? _c(
                "div",
                {
                  staticClass: "iframeContainer",
                  style: { top: _vm.iframeTop + "px" },
                },
                [
                  _c("div", { staticClass: "close-area" }, [
                    _c(
                      "div",
                      {
                        staticClass: "close-button",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.closeDetailFrame.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._m(0)]
                    ),
                  ]),
                  _c("iframe", {
                    staticClass: "iframe",
                    style: { height: _vm.iframeHeight + "px" },
                    attrs: {
                      id: "iframeElement",
                      src: _vm.iframeCompanyUrl,
                      frameborder: "0",
                    },
                  }),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "close-button-label" }, [
      _c("div", { staticClass: "title" }, [_vm._v(" 編集部の募集内容 ")]),
      _c("div", { staticClass: "close-icon-label" }, [
        _c("span", { staticClass: "icon" }, [_vm._v("×")]),
        _c("span", { staticClass: "label" }, [_vm._v("閉じる")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }