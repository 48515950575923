var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "contest-entry-complete" }, [
      _c("div", { staticClass: "image" }, [
        _c("img", {
          attrs: { src: require("@/assets/img/event/agent/complete.webp") },
        }),
      ]),
      _c("h2", [_vm._v("応募受付を完了しました")]),
      _c("div", { staticClass: "content" }, [
        _c("p", [_vm._v("ご応募くださりありがとうございました。")]),
        _c("p", [
          _vm._v(" エージェント提出していただいた作品は、Nolaサービス内の "),
          _c("span", { staticClass: "font-bold" }, [
            _vm._v("『エージェント』画面"),
          ]),
          _vm._v(" からご確認いただけます。 "),
        ]),
        _c("p", [
          _vm._v(
            " なお、キャンペーンの当選結果の発表前に提出の取り消しをすると、本キャンペーンの対象外となりますのでお気をつけください。 "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }