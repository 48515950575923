
import Vue from "vue";
import BannerList from "@/components/organisms/BannerList.vue";
import ContestNotification from "@/components/organisms/ContestNotification.vue";
import WritingData from "@/components/organisms/WritingData.vue";
import NolaNovelData from "@/components/organisms/NolaNovelData.vue";
// import UserProfile from "@/components/organisms/UserProfile.vue";
import NovelList from "@/components/organisms/NovelList.vue";
import { showNotifyDialog } from "@/lib/dialog";
import { isTwitterLogin } from "@/lib/twitterAuth";
import { Auth } from "aws-amplify";
import { checkDeprecatedBrowser } from "@/lib/checkDeprecatedBrowser";
import isMobile from "ismobilejs";
import { format } from "date-fns";
import axiosBase from "axios";
import { News } from "@/lib/models/general";
import { ConnectedOtherServices, Novel } from "@/lib/models";
import MaskedLoading from "@/components/atoms/MaskedLoading.vue";
import { Dialog } from "@/lib/utils";
import AgentPromotionDialog from "@/components/ui/AgentPromotionDialog.vue";
import Onboarding from "@/components/molecules/OnBoarding.vue";
import ImportTutorialDialog from "@/components/molecules/ImportTutorialDialog.vue";
import FirstImportOnboarding from "@/components/organisms/FirstImportOnboarding.vue";
import { NolaAnalytics, NolaItemId, NolaContentType } from "@/lib/utils/analytics";
import { Tutorial } from "@/lib/models/tutorial";

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_MICROCMS_API_KEY },
});

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: {
    // UserProfile,
    ContestNotification,
    WritingData,
    NolaNovelData,
    BannerList,
    NovelList,
    MaskedLoading,
    Onboarding,
    ImportTutorialDialog,
    FirstImportOnboarding,
  },
  data() {
    return {
      username: null,
      banner: [],
      news: [],
      contests: [],
      checkedTwitterLogin: false,
      isLoading: false,
      showFirstOnboarding: false,
      nolaAnalytics: new NolaAnalytics(this.$gtm, this.$store),
      showImportTutorial: false,
      isImporting: false,
      isDoneImportTutorial: false,
      isNovelCreated: false,
      showImportOnboarding: false,
      tutorialStepIndex: 0,
    };
  },
  computed: {
    novel() {
      return (novelId) => this.$store.getters["novelModule/novel"](novelId);
    },
    novels() {
      return this.$store.getters["novelModule/novels"];
    },
    isPC() {
      return !isMobile().any;
    },
    isConnectedNolaNovel() {
      const connectedOtherServices = this.$store.getters["userModule/connectedOtherServices"] as ConnectedOtherServices;
      return connectedOtherServices.nolaNovel.result;
    },
    agentPopupDisplayFlagNovelId() {
      return this.$store.getters["generalModule/agentPopupDisplayFlagNovelId"];
    },
  },
  methods: {
    async checkTwitterLogin() {
      if (
        isTwitterLogin() &&
        (!this.novels || !this.novels.length) &&
        sessionStorage.getItem("TESTING") === undefined
      ) {
        if (
          window.confirm(
            "Twitterログインは新規ユーザーは利用できません。\nメールアドレスで会員登録、ログインしてください。\n\nログアウトしますか？"
          )
        ) {
          await Auth.signOut();
          window.localStorage.clear();
          // alert('Twitterログインは新規ユーザーは利用できません。\nメールアドレスで会員登録、ログインしてください。');
          window.location.href = "/lp";
        }
      }
    },
    async getNews() {
      const { isPC } = this;

      const bannerRequest = await axios.get(`/bannerweb?limit=${isPC ? 2 : 1}`);
      const newsRequest = await axios.get("/news?limit=1");

      if (bannerRequest.status !== 200 || newsRequest.status !== 200) {
        await showNotifyDialog({
          title: "エラー",
          content: "お知らせの取得に失敗しました。",
        });
      }

      this.banner = bannerRequest.data.contents;
      this.news = newsRequest.data.contents;

      const latestNews = this.news[0];
      this.$store.commit("generalModule/setLatestNews", latestNews);
    },
    changeLoading(value) {
      this.isLoading = value;
    },
    async checkTutorial() {
      const { $store, novels } = this;
      // インポート中はチェックしない
      if (this.isImporting) return;
      const [firstStatus, importStatus, manuscriptStatus] = await Promise.all([
        $store.getters["tutorialModule/tutorial"]("home", "first"),
        $store.getters["tutorialModule/tutorial"]("home", "import"),
        $store.getters["tutorialModule/tutorial"]("manuscript", "first"),
      ]);
      if (firstStatus && firstStatus.step === "before") {
        // はじめてのNolaがあればチュートリアルの表示
        if (novels.length > 0 && novels.findIndex((i) => i.title === "はじめてのNola") !== -1) {
          this.showFirstOnboarding = true;
          // インプレッションイベントの送信
          this.nolaAnalytics.logViewItemEvent(NolaItemId.TopOnboarding, NolaContentType.Onboarding);
        }
      } else if (
        firstStatus &&
        firstStatus.step === "done" &&
        !importStatus &&
        manuscriptStatus &&
        manuscriptStatus.step === "done"
      ) {
        // 執筆画面のチュートリアルが完了している人はインポートチュートリアル表示へ
        if (!this.isDoneImportTutorial) {
          this.showImportTutorial = true;
          this.nolaAnalytics.logViewItemEvent(NolaItemId.TopOnboardingImport, NolaContentType.Onboarding);
          this.isDoneImportTutorial = true;
          this.isImporting = true;
        }
      } else if (
        firstStatus &&
        firstStatus.step === "done" &&
        !importStatus &&
        (!manuscriptStatus || manuscriptStatus.step !== "done")
      ) {
        this.isNovelCreated = novels.findIndex((i) => i.title !== "はじめてのNola") !== -1;
        // 執筆画面のチュートリアルはまだで、はじめてのNola以外の作品を作っている人はインポートチュートリアル表示へ
        if (this.isNovelCreated && !this.isDoneImportTutorial) {
          this.showImportTutorial = true;
          this.nolaAnalytics.logViewItemEvent(NolaItemId.TopOnboardingImport, NolaContentType.Onboarding);
          this.isDoneImportTutorial = true;
          this.isImporting = true;
        }
      } else if (firstStatus && firstStatus.step === "done" && importStatus && importStatus.step === "before") {
        // ステップ1からの表示
        this.tutorialStepIndex = 0;
        this.showImportOnboarding = true;
        this.nolaAnalytics.logViewItemEvent(NolaItemId.TopOnboardingAgent, NolaContentType.Onboarding);
      } else if (firstStatus && firstStatus.step === "done" && importStatus && importStatus.step === "step1") {
        // ステップ2からの表示
        this.tutorialStepIndex = 1;
        this.showImportOnboarding = true;
        this.nolaAnalytics.logViewItemEvent(NolaItemId.TopOnboardingCreate, NolaContentType.Onboarding);
      }
    },
    handleOnboardingFirstFinished() {
      // indexedDBの値を更新する
      this.$store.dispatch("tutorialModule/updateTutorial", {
        screen: "home",
        category: "first",
        step: "done",
      });
    },
    onboardingFirstCloseClicked() {
      this.nolaAnalytics.logButtonEvent(NolaItemId.TopOnboardingClose);
    },
    onboardingFirstTargetClicked() {
      this.nolaAnalytics.logButtonEvent(NolaItemId.TopOnboardingNovel);
    },
    onboardingFirstOutsideClicked() {
      this.showFirstOnboarding = false;
      this.nolaAnalytics.logButtonEvent(NolaItemId.TopOnboardingOutside);
    },
    async onboardingImportClicked() {
      this.nolaAnalytics.logButtonEvent(NolaItemId.TopOnboardingImport);
      this.showImportTutorial = false;
      const tutorial: Tutorial = {
        screen: "home",
        category: "import",
        step: "before",
      };

      await this.$store.dispatch("tutorialModule/createTutorial", {
        tutorial,
      });
      // novelListにインポートダイアログ出す指示を出す
      (this.$refs.novelList as InstanceType<typeof NovelList>).onClickImportFile(true);
    },
    async onboardingSkipClicked() {
      // チュートリアルデータ作成して、表示もする
      this.nolaAnalytics.logButtonEvent(NolaItemId.TopOnboardingImportSkip);
      this.showImportTutorial = false;
      const tutorial: Tutorial = {
        screen: "home",
        category: "import",
        step: "step1",
      };

      await this.$store.dispatch("tutorialModule/createTutorial", {
        tutorial,
      });
      this.showImportOnboarding = true;
      this.nolaAnalytics.logViewItemEvent(NolaItemId.TopOnboardingAgent, NolaContentType.Onboarding);
    },
    onImportTutorialTargetClicked(index: number) {
      if (index === 0) {
        // エージェント押下時
        this.$store.dispatch("tutorialModule/updateTutorial", {
          screen: "home",
          category: "import",
          step: "step1",
        });
      } else if (index === 1) {
        // 新規作成ボタン押下
        this.$store.dispatch("tutorialModule/updateTutorial", {
          screen: "home",
          category: "import",
          step: "done",
        });
      }
    },
    onImportTutorialCompleted() {
      this.$store.dispatch("tutorialModule/updateTutorial", {
        screen: "home",
        category: "import",
        step: "done",
      });
    },
    onImportTutorialOutsideClicked() {
      this.$store.dispatch("tutorialModule/updateTutorial", {
        screen: "home",
        category: "import",
        step: "step1",
      });
    },
    async importEnd() {
      this.isImporting = false;
    },
    async importEndOutside() {
      this.isImporting = false;
      this.showImportOnboarding = true;
      this.nolaAnalytics.logViewItemEvent(NolaItemId.TopOnboardingAgent, NolaContentType.Onboarding);
    },
    importStart() {
      // 通常のインポートでもnovelsの数が変わってチェックが走るので、そうならないためにインポートフラグを立てる
      this.isImporting = true;
    },
  },
  async created() {
    const { $store } = this;

    /** 非推奨ブラウザへの文言表示 */
    checkDeprecatedBrowser();

    await $store.dispatch("userModule/initialize");
    $store.dispatch("novelModule/initialize");
    $store.dispatch("memoModule/initialize");
    $store.dispatch("manuscriptModule/initializeGeneralSettings");
    $store.dispatch("novelModule/initializeManuscriptSettings");
    $store.dispatch("tutorialModule/initialize");

    if (this.isConnectedNolaNovel) {
      $store.dispatch("nolaNovelModule/fetchNovels");
    }

    const connectedOtherServices = this.$store.getters["userModule/connectedOtherServices"] as ConnectedOtherServices;
    if (connectedOtherServices.nolaNovel.result)
      this.$store.dispatch("nolaNovelModule/fetchAllNotifications", { isRead: false });

    await this.getNews();

    const currentDate = format(new Date(), "YYYY-MM-DD");

    const contestRequest = await axios.get(`/contest?filters=deadline[greater_than]${currentDate}&limit=1`);
    if (contestRequest.status !== 200) {
      await showNotifyDialog({
        title: "エラー",
        content: "コンテスト情報の取得に失敗しました。",
      });
    }
    this.contests = contestRequest.data.contents;

    // エージェント機能のポップアップ表示のフラグチェック
    if (this.agentPopupDisplayFlagNovelId) {
      const novel = this.novel(this.agentPopupDisplayFlagNovelId);

      // ポップアップ表示
      const agentPromotionDialog = new Dialog(AgentPromotionDialog);
      agentPromotionDialog.show({ novelTitle: novel!.title });

      // 表示済みとする
      await this.$store.dispatch("generalModule/addDisplayedAgentPopupForNovel", this.agentPopupDisplayFlagNovelId);

      // フラグを初期化する
      this.$store.commit("generalModule/setAgentPopupDisplayFlagNovelId", null);
    }

    // エージェントのお知らせの未読数を取得する
    this.$store.dispatch("notificationModule/getNotificationSummary", {
      notificationGroup: "agent",
    });
  },
  watch: {
    novels(newVal) {
      if (newVal.length > 0) {
        // mountedではnovelsがロードされていない場合があるのでここでチェック
        this.checkTutorial();
      }
      if (!this.checkedTwitterLogin) {
        this.checkTwitterLogin();
        this.checkedTwitterLogin = true;
      }
    },
  },
});

interface Props {}

interface Data {
  username: string | null;
  banner: object[];
  news: News[];
  contests: object[];
  checkedTwitterLogin: boolean;
  isLoading: boolean;
  showFirstOnboarding: boolean;
  nolaAnalytics: NolaAnalytics;
  showImportTutorial: boolean;
  isImporting: boolean;
  isDoneImportTutorial: boolean;
  isNovelCreated: boolean;
  showImportOnboarding: boolean;
  tutorialStepIndex: number;
}

interface Computed {
  novel(novelId: string): Novel;
  novels: Novel[];
  isPC: boolean;
  isConnectedNolaNovel?: boolean;
  agentPopupDisplayFlagNovelId: string | null;
}

interface Methods {
  checkTwitterLogin(): void;
  getNews(): Promise<void>;
  changeLoading(value: boolean): void;
  checkTutorial(): void;
  handleOnboardingFirstFinished(): void;
  onboardingFirstCloseClicked(): void;
  onboardingFirstTargetClicked(): void;
  onboardingFirstOutsideClicked(): void;
  onboardingImportClicked(): void;
  onboardingSkipClicked(): void;
  onImportTutorialCompleted(): void;
  onImportTutorialTargetClicked(index: number): void;
  onImportTutorialOutsideClicked(): void;
  importEnd(): void;
  importEndOutside(): void;
  importStart(): void;
}
