var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "novel-book-root",
      on: { mouseover: _vm.onMouseOver, mouseleave: _vm.onMouseLeave },
    },
    [
      _vm.isMenuOpen ? _c("div", { staticClass: "overlay" }) : _vm._e(),
      _c(
        "router-link",
        {
          staticClass: "novel-book",
          class: { "tutorial-novel": _vm.novel.title === "はじめてのNola" },
          attrs: {
            to: { name: "editor", params: { novelId: _vm.novel.novelId } },
          },
        },
        [
          _c("image-view", {
            staticClass: "image",
            attrs: {
              src: _vm.getImageKey,
              placeholder: "/img/placeholders/novel.png",
            },
          }),
          _c("div", { staticClass: "novel-title" }, [
            _c("div", { staticClass: "line-clamp" }, [
              _vm._v(_vm._s(_vm.novel.title)),
            ]),
            _c("div", { staticClass: "date" }, [
              _vm._v(_vm._s(`更新日：${_vm.toUpdatedAt}`)),
            ]),
            _c("div", { staticClass: "date" }, [
              _vm._v(_vm._s(`作成日：${_vm.toCreatedAt}`)),
            ]),
          ]),
        ],
        1
      ),
      _vm.isPC
        ? _c(
            "div",
            { staticClass: "pinned", on: { click: _vm.onClickPinned } },
            [
              _vm.pinned
                ? _c("pin-icon", {
                    staticClass: "pin-icon",
                    attrs: { fillColor: "#e87474" },
                  })
                : [
                    _vm.isMouseHover
                      ? _c("pin-outline-icon", {
                          staticClass: "pin-icon",
                          attrs: { fillColor: "#777" },
                        })
                      : _vm._e(),
                  ],
            ],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "dots-container", on: { click: _vm.toggleMenu } },
        [
          !_vm.isMenuOpen
            ? _c("dots-horizontal-circle-outline-icon")
            : _vm._e(),
        ],
        1
      ),
      _vm.isMenuOpen
        ? _c("div", { staticClass: "menu" }, [
            _c(
              "button",
              { on: { click: _vm.showCopyNovelDialog } },
              [
                _c("content-copy-icon", { attrs: { size: 20 } }),
                _c("span", [_vm._v("作品を複製する")]),
              ],
              1
            ),
            _c(
              "button",
              { on: { click: _vm.importNovel } },
              [
                _c("download-icon", { attrs: { size: 20 } }),
                _c("span", [_vm._v("原稿をインポート")]),
              ],
              1
            ),
            _c(
              "button",
              { on: { click: _vm.deleteNovel } },
              [
                _c("trash-can-outline-icon", {
                  attrs: { fillColor: "#c30407", size: 20 },
                }),
                _c("span", { staticClass: "delete-button-label" }, [
                  _vm._v("作品を削除する"),
                ]),
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass: "close-button",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toggleMenu.apply(null, arguments)
                  },
                },
              },
              [
                _c("span", { staticClass: "close-button-label" }, [
                  _vm._v("メニューを閉じる"),
                ]),
              ]
            ),
            _c("input", {
              ref: "input",
              staticStyle: { display: "none" },
              attrs: { type: "file", accept: ".txt,.docx", multiple: "" },
              on: {
                change: function ($event) {
                  return _vm.onSelectFile()
                },
              },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }