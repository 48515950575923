var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "event-agent-header" }, [
        _c("h2", [_vm._v("エージェント提出する作品を選択")]),
        _c("div", { staticClass: "button-wrapper" }, [
          _c(
            "button",
            {
              staticClass: "next",
              attrs: { disabled: _vm.selectedNovelIds.length === 0 },
              on: { click: _vm.submit },
            },
            [_vm._v("選択した作品を応募する")]
          ),
        ]),
      ]),
      _vm.targetNovels.length === 0
        ? [_vm._m(0)]
        : [
            _c(
              "div",
              { staticClass: "novel-list" },
              _vm._l(_vm.targetNovels, function (novel) {
                return _c("AgentCampaignNovelListItem", {
                  key: novel.novelId,
                  attrs: {
                    campaignId: _vm.$route.params.campaignId,
                    novel: novel,
                    selectedNovelIds: _vm.selectedNovelIds,
                  },
                  on: {
                    input: function ($event) {
                      _vm.selectedNovelIds = $event
                    },
                  },
                })
              }),
              1
            ),
          ],
      _vm.loading ? _c("MaskedLoading", { staticClass: "loading" }) : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "novel-empty" }, [
      _c("div", { staticClass: "title" }, [
        _vm._v("応募可能な作品がありません"),
      ]),
      _c("div", { staticClass: "message" }, [
        _vm._v("作品を作成してNolaエージェントに提出しよう！"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }