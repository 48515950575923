
import Vue from "vue";

interface Methods {
  importClicked: () => void;
  skipClicked: () => void;
}

interface Props {
  close?: () => void;
  isNovelCreated: boolean;
}

export default Vue.extend<{}, Methods, {}, Props>({
  methods: {
    importClicked() {
      const { close } = this;
      close();
      this.$emit("onboarding-import-clicked");
    },
    skipClicked() {
      const { close } = this;
      close();
      this.$emit("onboarding-skip-clicked");
    },
  },
  props: {
    close: Function,
    isNovelCreated: Boolean,
  },
});
