
import Vue from "vue";
import ImageView from "@/components/atoms/ImageView.vue";
import { Novel } from "@/lib/models";
import { workStatus } from "@/lib/novelSettings";
import { NovelSetting } from "@/lib/models/novel";

interface Data {
  isHovered: boolean;
  showGenrePopup: boolean;
}

interface Methods {
  submit: (event: Event) => void;
  hideGenrePopup: () => void;
  isGenreOrCategoryUnset: () => boolean;
}

interface Computed {
  novel: Novel | null;
  imageKey?: string | null;
  currentWorkStatus: NovelSetting;
  workStatusButtonStyle: { backgroundColor: string };
  hoverStyle: { backgroundColor: string };
  isGenreSettingPopupDisplayedForNovel: boolean;
}

interface Props {
  novelId: string;
  expand: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  components: { ImageView },

  props: {
    novelId: String,
    expand: Boolean,
  },

  data() {
    return {
      isHovered: false,
      showGenrePopup: false,
    };
  },

  mounted() {
    // ジャンルまたはカテゴリが未設定かつ、ジャンル設定ポップアップが表示されていない場合
    if (this.isGenreOrCategoryUnset() && !this.isGenreSettingPopupDisplayedForNovel) {
      this.showGenrePopup = true;
    }
  },

  computed: {
    novel() {
      const { novelId } = this;
      const { getters } = this.$store;
      return getters["novelModule/novel"](novelId);
    },
    imageKey() {
      if (!this.novel) {
        return null;
      }

      if (this.novel && this.novel.image && this.novel.image.startsWith("file:")) {
        return `file:novels/${this.novelId}/cover.jpg`;
      }

      return this.novel.image;
    },
    currentWorkStatus() {
      const { novel } = this;
      return workStatus.find((status) => status.id === novel.workStatus) || workStatus[0];
    },
    workStatusButtonStyle() {
      const colors = {
        inProgress: "#67AAE7",
        completed: "#72B947",
        pending: "#A0A0A0",
        suspend: "#D45E5E",
      };
      return {
        backgroundColor: colors[this.currentWorkStatus.id] || "#67AAE7",
      };
    },
    hoverStyle() {
      const hoverColors = {
        inProgress: "#5592C7",
        completed: "#5A9A36",
        pending: "#808080",
        suspend: "#B04D4D",
      };
      return {
        backgroundColor: hoverColors[this.currentWorkStatus.id] || "#5592C7",
      };
    },
    isGenreSettingPopupDisplayedForNovel() {
      const { $store, novelId } = this;
      return $store.getters["generalModule/isGenreSettingPopupDisplayedForNovel"](novelId);
    },
  },

  methods: {
    submit(event: Event) {
      const { novelId } = this;

      const target = event.target as HTMLElement;

      // `work-status-button` またはその子要素がクリックされた場合
      if (target.closest(".work-status-button")) {
        this.$router.push({ name: "detailEdit", params: { novelId: this.novelId } });
        return;
      }

      // それ以外の場所がクリックされた場合
      this.$router.push({ name: "detail", params: { novelId } });
    },
    async hideGenrePopup() {
      this.showGenrePopup = false;
      // 表示中の作品のジャンル設定ポップアップが閉じられたことを記録
      await this.$store.dispatch("generalModule/addDisplayedGenreSettingPopupForNovel", this.novelId);
    },
    isGenreOrCategoryUnset() {
      return !this.novel.genre || !this.novel.category;
    },
  },

  watch: {
    novel: {
      handler(newVal) {
        if (newVal?.genre && newVal?.category) {
          this.showGenrePopup = false;
        }
      },
      deep: true,
    },
  },
});
