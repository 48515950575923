var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "dialog-mask" }, [
    _c("div", { staticClass: "dialog-content" }, [
      _c(
        "button",
        {
          staticClass: "dialog-close-button",
          on: { click: _setup.closeDialog },
        },
        [_c(_setup.CloseCircleOutlineIcon, { attrs: { size: 22 } })],
        1
      ),
      _vm._m(0),
      _c("h3", { staticClass: "dialog-title" }, [
        _vm._v("Nolaのエージェント機能とは？"),
      ]),
      _c("p", { staticClass: "dialog-description" }, [
        _vm._v(
          " Nolaが、作家の皆さまに代わって、作品の可能性を探る機能です。作品をエージェントに提出すると、書籍化やコミック化など、新たな道が広がります。 下記よりご確認ください🐈‍⬛ "
        ),
      ]),
      _c(
        "button",
        {
          staticClass: "dialog-action-button",
          on: { click: _setup.handleAgentNavigation },
        },
        [_vm._v("Nolaエージェントに移動する")]
      ),
      _c("p", { staticClass: "dialog-note" }, [
        _vm._v(" ※現在ご執筆中の作品は"),
        _c("span", [
          _vm._v(_vm._s(_setup.isSaved ? "保存済みです。" : "保存されます。")),
        ]),
        _c("br"),
        _vm._v(" 安心してページをご移動ください。 "),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dialog-image-container" }, [
      _c("img", {
        staticClass: "dialog-image",
        attrs: { src: require("@/assets/img/agent/handshake.webp") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }