var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "root" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          {
            staticClass: "header-item",
            class: { active: _vm.isModeAssociatedNovels },
            on: { click: _vm.onClickAssociatedNovels },
          },
          [
            _vm._v(
              " " +
                _vm._s(`投稿済み作品（${_vm.associatedNovels.length}）`) +
                " "
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "header-item",
            class: { active: !_vm.isModeAssociatedNovels },
            on: { click: _vm.onClickNonAssociatedNovels },
          },
          [
            _vm._v(
              " " +
                _vm._s(`未投稿作品（${_vm.nonAssociatedNovels.length}）`) +
                " "
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "body" },
        [
          _vm.isLoading
            ? _c("vue-loading", {
                attrs: {
                  type: "spiningDubbles",
                  color: "#efad4c",
                  size: { width: "50px", height: "50px" },
                },
              })
            : _vm.isModeAssociatedNovels && _vm.associatedNovels.length
            ? _vm._l(_vm.associatedNovels, function (novel) {
                return _c("NolaNovelAssociatedNovelListItem", {
                  key: novel.novelId,
                  attrs: {
                    novel: novel,
                    novelFromNolaNovel: _vm.novelFromNolaNovel(novel),
                  },
                })
              })
            : !_vm.isModeAssociatedNovels && _vm.nonAssociatedNovels.length
            ? _vm._l(_vm.nonAssociatedNovels, function (novel) {
                return _c("NolaNovelNonAssociatedNovelListItem", {
                  key: novel.novelId,
                  attrs: { novel: novel },
                })
              })
            : [
                _c("NolaNovelEmptyList", {
                  attrs: { isModeAssociatedNovels: _vm.isModeAssociatedNovels },
                  on: {
                    clickNonAssociatedNovels: _vm.onClickNonAssociatedNovels,
                  },
                }),
              ],
        ],
        2
      ),
      _vm.showBeginnerIconOnboarding ? _c("BeginnerIconOnboarding") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }