var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class: { "side-menu-novel-root": true, shrink: !_vm.expand },
      on: { click: _vm.submit },
    },
    [
      _vm.novel
        ? _c("div", [
            _c(
              "div",
              { staticClass: "grid-container", class: { shrink: !_vm.expand } },
              [
                _c(
                  "transition",
                  { attrs: { name: "fade-fast" } },
                  [
                    _c("image-view", {
                      class: { "novel-image": true, shrink: !_vm.expand },
                      attrs: {
                        src: _vm.imageKey,
                        placeholder: "/img/placeholders/novel.png",
                      },
                    }),
                  ],
                  1
                ),
                _vm.expand
                  ? _c(
                      "div",
                      {
                        staticClass: "novel-title",
                        class: { shrink: !_vm.expand },
                      },
                      [_vm._v(" " + _vm._s(_vm.novel.title) + " ")]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "work-status-button",
                    class: { shrink: !_vm.expand },
                    style: _vm.isHovered
                      ? _vm.hoverStyle
                      : _vm.workStatusButtonStyle,
                    on: {
                      mouseover: function ($event) {
                        _vm.isHovered = true
                      },
                      mouseleave: function ($event) {
                        _vm.isHovered = false
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "status-name" }, [
                      _vm._v(_vm._s(_vm.currentWorkStatus.name)),
                    ]),
                    _c("span", { staticClass: "dropdown-icon" }, [
                      _c(
                        "svg",
                        {
                          staticClass: "bi bi-chevron-down",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            fill: "currentColor",
                            stroke: "currentColor",
                            "stroke-width": "2",
                            viewBox: "0 0 16 16",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              d:
                                "M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }