
import Vue, { PropType } from "vue";
import { compareAsc, format } from "date-fns";
import ja from "date-fns/locale/ja";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    contest: Object as PropType<Contest>,
  },
  computed: {
    id() {
      const { contest } = this;
      return contest.id;
    },
    title() {
      const { contest } = this;
      return contest.contest;
    },
    department() {
      const { contest } = this;
      return contest.department;
    },
    free() {
      const { contest } = this;
      return contest.free;
    },
    tag() {
      const { genre, category } = this;

      let tag = "";

      if (genre.length) {
        genre.forEach((x) => (tag += `#${x} `));
      }

      if (category.length) {
        category.forEach((x) => (tag += `#${x} `));
      }

      return tag;
    },
    image() {
      const { contest } = this;
      const { url } = contest.image;
      return url && `${url}?fit=fill&fill=blur&w=600&h=315`;
    },
    deadline() {
      const { contest } = this;
      const { deadline } = contest;

      if (!deadline) return "-";

      return format(deadline, "YYYY/MM/DD（dd） HH:mm", { locale: ja });
    },
    genre() {
      const { contest } = this;
      return contest.genre;
    },
    category() {
      const { contest } = this;
      return contest.category;
    },
    award() {
      const { contest } = this;
      return contest.award;
    },
    content() {
      const { contest } = this;
      return contest.content;
    },
    homepage() {
      const { contest } = this;
      return contest.homepage;
    },
    status() {
      const { contest } = this;

      const currentDate = new Date();
      const contestDeadline = new Date(contest.deadline);

      const isValid = compareAsc(currentDate, contestDeadline) !== 1;

      return isValid ? "募集中" : "募集終了";
    },
  },
  methods: {
    onClickCard() {
      const { $router, id } = this;
      $router.push({ name: "eventContestDetail", params: { id } });
    },
  },
});

interface Props {
  contest: Contest;
}

interface Data {}

interface Computed {
  id: string;
  title: string;
  department: string;
  free: string;
  tag: string;
  image?: string;
  deadline: string;
  genre: string[];
  category: string[];
  award: string;
  content: string;
  homepage: string;
  status: string;
}

interface Methods {
  onClickCard(): void;
}

type Contest = {
  [key: string]: any;
};
