var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "dialog-mask",
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          return _vm.onClickClose.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "dialog-box" }, [
        _c(
          "div",
          { staticClass: "close-button", on: { click: _vm.onClickClose } },
          [
            _c("div", { staticClass: "icon" }, [_c("Close")], 1),
            _c("div", { staticClass: "label" }, [_vm._v(" 閉じる ")]),
          ]
        ),
        _c("div", { staticClass: "dialog-title" }, [
          _vm._v(" 編集部の掲示板とは？ "),
        ]),
        _vm._m(0),
        _vm._m(1),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "main-image" }, [
      _c("img", {
        staticClass: "image",
        attrs: {
          src: require("@/assets/img/event/company/lying_cat.png"),
          alt: "main_image",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", [
      _c("div", { staticClass: "description-title" }, [_vm._v(" 主な機能 ")]),
      _c("div", { staticClass: "description-container" }, [
        _c("div", { staticClass: "description-box" }, [
          _c("img", {
            staticClass: "icon",
            attrs: {
              src: require("@/assets/img/event/company/reader.png"),
              alt: "reader_icon",
            },
          }),
          _c("div", [
            _c("div", { staticClass: "title" }, [
              _vm._v(" 読者＋編集者に届く "),
            ]),
            _c("div", { staticClass: "text" }, [
              _vm._v(
                " 一般的な投稿サイトと同様に自分の好きな作品を投稿できることに加え、編集部のハッシュタグをつけることで、編集者にも作品が届けることができます。 "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "description-box" }, [
          _c("img", {
            staticClass: "icon",
            attrs: {
              src: require("@/assets/img/event/company/board.png"),
              alt: "board_icon",
            },
          }),
          _c("div", [
            _c("div", { staticClass: "title" }, [
              _vm._v(" 編集部の探している作品がわかる "),
            ]),
            _c("div", { staticClass: "text" }, [
              _vm._v(
                " 各編集部の探している作品像がわかるので、自分に合った編集部を見つけて、商業デビューを狙った作品制作ができます。 "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "description-box" }, [
          _c("img", {
            staticClass: "icon",
            attrs: {
              src: require("@/assets/img/event/company/reaction.png"),
              alt: "reaction_icon",
            },
          }),
          _c("div", [
            _c("div", { staticClass: "title" }, [
              _vm._v(" 編集部からリアクションが届く "),
            ]),
            _c("div", { staticClass: "text" }, [
              _vm._v(
                " 編集者が作品を閲覧すると『編集部の足あと』が届くので、作品が読んでもらえたのか明確にわかります。その他にも、ブックマークや感想、商業化の打診が届くこともあります。 "
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }