
import Vue from "vue";
import Onboarding from "@/components/molecules/OnBoarding.vue";
import { NolaAnalytics, NolaItemId } from "@/lib/utils/analytics";

interface StepData {
  targetSelector: string;
  message: string;
  tooltipPosition: string;
  highlightPadding: object;
  closeText: string;
  circle: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    currentStepIndex: {
      type: Number,
      default: 0,
    },
  },
  components: { Onboarding },
  data() {
    return {
      isVisible: true,
      steps: [
        {
          targetSelector: ".tutorial-agent",
          message:
            "<div style='font-size:16px;margin-bottom:16px;'>作品をエージェントに連携すると<span style='color:#f1b700;'>商業デビュー<br>の道</span>が広がります！ぜひご確認ください。&#x1F408;&#x200D;&#x2B1B;&#x2728;</div>",
          tooltipPosition: "bottom-left",
          highlightPadding: { top: 6, bottom: 6, left: 6, right: 6 },
          closeText: "次へ(1/2)",
        },
        {
          targetSelector: ".tutorial-novel-create",
          message:
            "<div style='font-size:16px;margin-bottom:6px;'><span style='color:#f1b700;'>新しい作品</span>はこちらのボタンから追加して、<br>執筆をはじめましょう！</div>",
          tooltipPosition: "left-top",
          highlightPadding: { top: 0, bottom: 0, left: 0, right: 0 },
          closeText: "閉じる",
          circle: true,
        },
      ] as StepData[],
      nolaAnalytics: new NolaAnalytics(this.$gtm, this.$store),
    };
  },
  computed: {
    currentStepData() {
      return this.steps[this.currentStepIndex] || null;
    },
  },
  methods: {
    nextStep() {
      if (this.currentStepIndex < this.steps.length - 1) {
        this.$emit("add-currentStepIndex");
      } else {
        this.isVisible = false;
        this.$emit("tutorial-completed");
      }
    },
    closeOnboarding() {
      this.nextStep();
      if (this.currentStepIndex === this.steps.length - 1) {
        // 閉じるのクリック
        this.nolaAnalytics.logButtonEvent(NolaItemId.TopOnboardingCreateClose);
      } else {
        // 次へのクリック
        this.nolaAnalytics.logButtonEvent(NolaItemId.TopOnboardingNext);
      }
    },
    outsideClicked() {
      this.isVisible = false;
      // step2で閉じるときは完了
      if (this.currentStepIndex === this.steps.length - 1) {
        this.$emit("tutorial-completed");
      } else {
        // step1で外側のクリックは通知する
        this.$emit("outside-clicked");
      }
      this.nolaAnalytics.logButtonEvent(NolaItemId.TopOnboardingCreateOutside);
    },
    onboardingTargetClicked() {
      this.$emit("tutorial-target-clicked", this.currentStepIndex);
      if (this.currentStepIndex === this.steps.length - 1) {
        // 新規作成のクリック
        this.nolaAnalytics.logButtonEvent(NolaItemId.TopOnboardingCreate);
      } else {
        // エージェントのクリック
        this.nolaAnalytics.logButtonEvent(NolaItemId.TopOnboardingAgent);
      }
    },
  },
});

interface Props {
  currentStepIndex: number;
}

interface Data {
  isVisible: boolean;
  steps: StepData[];
  nolaAnalytics: NolaAnalytics;
}

interface Computed {
  currentStepData: StepData | null;
}

interface Methods {
  nextStep(): void;
  closeOnboarding(): void;
  outsideClicked(): void;
  onboardingTargetClicked(): void;
}
