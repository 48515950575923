
import Vue from "vue";
import { setTimeout } from "timers";

export default Vue.extend({
  props: {
    loaded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      early: true,
    };
  },
  created() {
    setTimeout(() => {
      this.early = false;
    }, 500);
  },
});
