
import Vue from "vue";
import { VueLoading } from "vue-loading-template";
import EventHeader from "@/components/organisms/EventHeader.vue";
import CompanyCard from "@/components/molecules/CompanyCard.vue";
import CompanyCardVerB from "@/components/molecules/CompanyCardVerB.vue";
import axiosBase from "axios";
import { showNotifyDialog } from "@/lib/dialog";
import { CategoryUtils } from "@/../nolaplatform-commons/src/utils/categoryGenreUtils";
import { Dialog } from "@/lib/utils";
import EventCompanyDialog from "@/components/ui/EventCompanyDialog.vue";
import { FirebaseRemoteConfigManager } from "@/lib/firebase/firebase";
import { remoteConfigKeys } from "@/lib/firebase/remoteConfigParams";
import { NolaAnalytics, NolaItemId } from "@/lib/utils/analytics";
import { Novel } from "@/lib/models";

const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_NOLANOVEL_MICROCMS_API_ENDPOINT,
  headers: { "X-API-KEY": process.env.VUE_APP_NOLANOVEL_MICROCMS_API_KEY },
});

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { VueLoading, EventHeader, CompanyCard, CompanyCardVerB },
  data() {
    return {
      nolaAnalytics: new NolaAnalytics(this.$gtm, this.$store),
      isLoading: true,
      companies: [],
      companyMatchedNovelIds: {},
      iframeCompanyUrl: "",
      iframeHeight: 0,
      iframeTop: 100,
      remoteConfigParams: {},
      firstTimeNolaTitle: "はじめてのNola", // 初めてNolaを利用する際に自動生成される書籍のタイトル
    };
  },
  async created() {
    await this.$store.dispatch("novelModule/initialize");

    // ポップアップ表示（初回のみ）
    if (this.$store.getters["generalModule/isVisibleNolaNovelCompanyRecommendPopup"]) {
      const eventCompanyDialog = new Dialog(EventCompanyDialog);
      eventCompanyDialog.show();

      // 次回以降ポップアップを非表示にする
      await this.$store.dispatch("generalModule/setVisibleNolaNovelCompanyRecommendPopup", false);
    }

    // Remote Configの値を取得
    const remoteConfig = FirebaseRemoteConfigManager.getInstance();
    this.remoteConfigParams = await remoteConfig.getRemoteConfigValue([remoteConfigKeys.company_post_button_ab_key]);

    const companyRequestUrl = "/company?limit=100";
    const company = await axios.get(companyRequestUrl);

    if (company.status !== 200) {
      await showNotifyDialog({
        title: "エラー",
        content: "編集部情報の取得に失敗しました。",
      });
    }

    this.companies = company.data.contents;

    // 自身の作品を取得 - 編集部カードの並び順ソートや、マッチした作品数を計算するために使用
    const novels: Novel[] | undefined = this.$store.getters["novelModule/novels"];

    // 作品が存在しない場合の考慮として、空配列を設定
    // 初めてNolaを利用する際に自動生成される書籍は編集部のジャンルと一致させたくないため、除外する
    const novelsWithoutFirstTimeNola: Novel[] = !novels
      ? []
      : novels.filter((novel) => novel.title !== this.firstTimeNolaTitle);

    // 編集部のカードをNolaノベル未投稿作品をもとに、ジャンルの一致度を計算してソートする
    this.sortCompaniesByGenreMatchScore(novelsWithoutFirstTimeNola);

    // 自身の作品の中から編集部のジャンルと一致する作品を抽出する
    this.extractCompanyTargetNovels(novelsWithoutFirstTimeNola);

    this.isLoading = false;

    // 編集部カードが表示されているかを監視する
    // isLoadingがfalseかつ、companiesが取得されカードが描画された後に実行するためにこの場所に記述
    this.$nextTick(() => {
      this.observeCompanyCard();
    });
  },
  mounted() {
    // windowがリサイズされた際にiframeの高さを更新する
    window.addEventListener("resize", this.updateIframeHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateIframeHeight);
  },
  methods: {
    /**
     * 編集部カードの並び順ソートを行う。
     * 編集部のジャンルと一致するNolaノベル未投稿作品の数が多い順にソートする。
     */
    sortCompaniesByGenreMatchScore(novels: Novel[]): void {
      // Nolaノベル未投稿作品を抽出
      // Nolaノベル未投稿: associatedDataがundefinedになる
      // Nolaノベルに投稿したがNolaノベルで作品を削除: associatedData.nolaNovel.idがundefinedになる
      // →そのため、オプショナルチェーンの使用
      const novelsNotAssociated: Novel[] = novels.filter((novel) => !novel.associatedData?.nolaNovel?.id);

      // 未投稿作品が無い場合はmicroCMS準拠の並び順のまま
      if (!novelsNotAssociated?.length) {
        return;
      }

      // 小説のジャンルをカウント（ジャンルの重みづけのため）
      const genreCounts: Record<string, number> = this.calculateGenreWeights(novelsNotAssociated);

      // ジャンルが見つからない場合、microCMS準拠の並び順のまま
      if (!Object.keys(genreCounts).length) {
        return;
      }

      // 各編集部とのマッチスコアを計算 / インデックスを保持
      const companyWithMatchScoreAndIndex = this.companies.map((company, index) => {
        const score = this.calculateCompanyMatchScore(genreCounts, company);
        return { ...company, matchScore: score, index };
      });

      // マッチスコアが高い順にソート
      this.companies = companyWithMatchScoreAndIndex.sort((a, b) => {
        if (a.matchScore !== b.matchScore) {
          return b.matchScore - a.matchScore;
        }

        // スコアが同一の場合は、元の順番を保持する
        return a.index - b.index;
      });
    },
    /**
     * 小説のジャンルをカウントし、各ジャンルの重みを計算する
     *
     * @param novels {Novel[]} - 小説のリスト
     */
    calculateGenreWeights(novels: Novel[]): Record<string, number> {
      // ジャンルはNolaジャンル
      const genreCounts: Record<string, number> = {};

      // eslint-disable-next-line no-restricted-syntax
      for (const novel of novels) {
        // カテゴリーは任意項目のため、カテゴリーがない場合はスキップ
        if (!novel.category) {
          // eslint-disable-next-line no-continue
          continue;
        }

        const processedGenre = new Set<string>();

        // eslint-disable-next-line no-restricted-syntax
        for (const category of novel.category) {
          // NolaカテゴリーをNolaノベルジャンルに変換
          const nolaNovelGenre = CategoryUtils.convertToNolaNovelGenre(category);

          // eslint-disable-next-line no-continue, no-restricted-syntax
          for (const genre of nolaNovelGenre) {
            // 1作品につき同一のジャンルは1回のみカウントするため、処理済みのジャンルはスキップ
            if (processedGenre.has(genre)) {
              // eslint-disable-next-line no-continue
              continue;
            }

            genreCounts[genre] = (genreCounts[genre] || 0) + 1;
            processedGenre.add(genre);
          }
        }
      }

      return genreCounts;
    },
    /**
     * 企業のマッチスコアを計算する
     */
    calculateCompanyMatchScore(genreCounts: Record<string, number>, company: any): number {
      let score = 0;

      // eslint-disable-next-line no-restricted-syntax
      for (const genre of company.novelGenreForSearch) {
        if (genreCounts[genre]) {
          score += genreCounts[genre];
        }
      }

      return score;
    },
    /**
     * 編集部のジャンルと一致する作品を抽出する
     */
    extractCompanyTargetNovels(novels: Novel[]) {
      novels.forEach((novel) => {
        // 既にNolaノベルへの連携がある場合はスキップ
        if (novel.associatedData) {
          return;
        }
        // 作品のカテゴリがない場合はスキップ
        if (!novel.category) {
          return;
        }

        // 作品のカテゴリをNolaノベルのジャンルに変換
        const nolaNovelGenreArray = novel.category.map((category) => CategoryUtils.convertToNolaNovelGenre(category));
        // 1カテゴリごとに配列で帰ってくるため、フラットにする
        const nolaNovelGenreArrayFlat = nolaNovelGenreArray.flat();

        // eslint-disable-next-line no-restricted-syntax
        for (const company of this.companies) {
          if (!this.companyMatchedNovelIds[company.id]) {
            this.companyMatchedNovelIds[company.id] = [];
          }
          // 編集部のジャンルと一致する作品を抽出 編集部の求めているジャンルは複数あるためforEachで回す
          // eslint-disable-next-line no-restricted-syntax
          for (const genre of company.novelGenreForSearch) {
            if (nolaNovelGenreArrayFlat.includes(genre)) {
              this.companyMatchedNovelIds[company.id].push(novel.novelId);

              // 一つでも一致したら次の編集部へ
              break;
            }
          }
        }
      });
    },
    /**
     * iframeを開き、urlで指定した編集部の"Nolaノベル - 編集部の掲示板"を表示する
     * @param url {string} - iframeを開くためのURL
     */
    openDetailFrame(url: string) {
      // iframeを開くためのURLを設定 - 設定したら表示される
      this.iframeCompanyUrl = url;
      this.updateIframeHeight();
    },
    /**
     * iframeを閉じる
     */
    closeDetailFrame() {
      // iframeCompanyUrlを空にすることでiframeが非表示になる
      this.iframeCompanyUrl = "";
    },
    /**
     * iframeの表示領域の高さを取得
     */
    updateIframeHeight() {
      // iframeのwindow sizeを取得
      const iframeWindowSize = window.innerHeight - this.iframeTop;
      // styleのiframeクラスにて、0.8倍に縮小表示しているため、1.25倍にして表示領域を確保
      this.iframeHeight = iframeWindowSize * 1.25;
    },
    /**
     * 編集部カードが表示されているかを監視する
     */
    observeCompanyCard() {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            // 表示中の場合に処理を実行
            if (entry.isIntersecting) {
              const vueInstance = (entry.target as HTMLElement & { __vue__: Vue }).__vue__ as any;
              if (this.companyPostButtonAbKey) {
                const itemId = NolaItemId[`ViewCompanyCard${this.companyPostButtonAbKey}`];
                this.nolaAnalytics.logButtonEvent(itemId, vueInstance.id);
              }
            }
          });
        },
        {
          threshold: 1, // カードが100%表示されたら処理を実行
        }
      );

      const companyCardComponents = this.$refs.companyCard;
      if (companyCardComponents) {
        companyCardComponents.forEach((companyCardComponent) => {
          observer.observe(companyCardComponent.$el);
        });
      }
    },
  },
  computed: {
    companyPostButtonAbKey() {
      return this.remoteConfigParams.company_post_button_ab_key;
    },
  },
});

interface Props {}

interface Data {
  isLoading: boolean;
  companies: Company[];
  companyMatchedNovelIds: { [key: string]: string[] };
  iframeCompanyUrl: string;
  iframeHeight: number;
  // iframeをposition: fixedで固定するための上からの位置 (表示領域の算出に使用するためscriptで保持)
  iframeTop: number;
  remoteConfigParams: { [key: string]: string };
  firstTimeNolaTitle: string;
}

interface Computed {
  companyPostButtonAbKey: string;
}

interface Methods {
  sortCompaniesByGenreMatchScore: (novels: Novel[]) => void;
  calculateGenreWeights: (novels: Novel[]) => Record<string, number>;
  extractCompanyTargetNovels: (novels: Novel[]) => void;
  calculateCompanyMatchScore: (genreCounts: Record<string, number>, company: Company) => number;
  openDetailFrame: (url: string) => void;
  closeDetailFrame: () => void;
  updateIframeHeight: () => void;
  observeCompanyCard: () => void;
}

interface Company {
  id: string;
}
