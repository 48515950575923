var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: { hidden: !_vm.showMenu }, attrs: { id: "side-menu" } },
    [
      _c(
        "div",
        { staticClass: "pc", class: _vm.expand ? "expand" : "shrink" },
        [
          _c(
            "div",
            { staticClass: "back-home" },
            [
              _c(
                "router-link",
                {
                  staticClass: "tutorial-back-home",
                  class: { expand: _vm.expand },
                  attrs: { to: { name: "home" } },
                },
                [
                  _vm.expand
                    ? _c("span", [_vm._v("＜ 書籍一覧に戻る")])
                    : _c("img", {
                        attrs: {
                          src: require("@/assets/img/icon/house.svg"),
                          alt: "home",
                          width: 30,
                        },
                      }),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tutorial-novel-top" },
            [
              _c("side-menu-novel", {
                attrs: { "novel-id": _vm.novelId, expand: _vm.expand },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "link-list tutorial-novel-bottom" },
            [
              _c("side-menu-link-list", {
                attrs: { "novel-id": _vm.novelId, expand: _vm.expand },
              }),
            ],
            1
          ),
          _c(
            "button",
            { staticClass: "btn-expand", on: { click: _vm.onClickExpand } },
            [
              _vm.expand
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/img/static/sidemenu-close.png"),
                    },
                  })
                : _c("img", {
                    attrs: {
                      src: require("@/assets/img/static/sidemenu-open.png"),
                    },
                  }),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "sp" },
        [
          _c(
            "side-menu-link-sp",
            { attrs: { novelId: _vm.novelId, link: "theme", label: "テーマ" } },
            [
              _c("img", {
                staticClass: "sp-icon",
                attrs: { src: require("@/assets/img/menu-icon/theme.png") },
              }),
            ]
          ),
          _c(
            "side-menu-link-sp",
            {
              attrs: { novelId: _vm.novelId, link: "plot", label: "プロット" },
            },
            [
              _c("img", {
                staticClass: "sp-icon",
                attrs: { src: require("@/assets/img/menu-icon/plot.png") },
              }),
            ]
          ),
          _c(
            "side-menu-link-sp",
            { attrs: { novelId: _vm.novelId, link: "editor", label: "執筆" } },
            [
              _c("img", {
                staticClass: "sp-icon",
                attrs: { src: require("@/assets/img/menu-icon/edit.png") },
              }),
            ]
          ),
          _c(
            "side-menu-link-sp",
            {
              attrs: {
                novelId: _vm.novelId,
                link: "characters",
                label: "設定",
              },
            },
            [
              _c("img", {
                staticClass: "sp-icon",
                attrs: { src: require("@/assets/img/menu-icon/character.png") },
              }),
            ]
          ),
          _c(
            "side-menu-link-sp",
            { attrs: { novelId: _vm.novelId, link: "detail", label: "書籍" } },
            [
              _c("img", {
                staticClass: "sp-icon",
                attrs: { src: require("@/assets/img/menu-icon/setting.png") },
              }),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }