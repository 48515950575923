
import Vue from "vue";
import InfiniteLoading, { StateChanger } from "vue-infinite-loading";
import { NotificationDataType, NotificationFromNolaNovel, NotificationsFromNolaNovel } from "@/lib/models";
import NolaNovelNotificationListItem from "@/components/molecules/NolaNovelNotificationListItem.vue";

const Tabs = {
  all: "all",
  reader: "reader",
  editor: "editor",
  another: "another",
} as const;
type TabType = typeof Tabs[keyof typeof Tabs];

export default Vue.extend<Data, Methods, Computed, Props>({
  // NOTE: metaタグの設定
  metaInfo: {
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
  components: { InfiniteLoading, NolaNovelNotificationListItem },
  async created() {
    const unreadNotifications = this.$store.getters["nolaNovelModule/notifications"](
      false
    ) as NotificationsFromNolaNovel;
    if (unreadNotifications.items.length === 0)
      await this.$store.dispatch("nolaNovelModule/fetchNotifications", { isRead: false });
  },
  data() {
    return {
      tabs: Tabs,
      currentTab: "all",
      timeout: undefined,
      isCompleteUnread: false,
      isCompleteFirstRead: false,
      isCompleteRead: false,
      isLoading: false,
    };
  },
  computed: {
    isActiveTab() {
      return (name) => this.currentTab === name;
    },
    isNoData() {
      return (
        this.isCompleteUnread &&
        this.isCompleteRead &&
        this.filteredUnreadNotifications.length === 0 &&
        this.filteredReadNotifications.length === 0
      );
    },
    unreadNotifications() {
      return this.$store.getters["nolaNovelModule/notifications"](false);
    },
    readNotifications() {
      return this.$store.getters["nolaNovelModule/notifications"](true);
    },
    filteredUnreadNotifications() {
      switch (this.currentTab) {
        case Tabs.all:
        default:
          return this.unreadNotifications.items;
        case Tabs.reader:
          return this.unreadNotifications.items.filter((item) => this.isReaderNotification(item));
        case Tabs.editor:
          return this.unreadNotifications.items.filter((item) => this.isEditorNotification(item));
        case Tabs.another:
          return this.unreadNotifications.items.filter(
            (item) => !this.isReaderNotification(item) && !this.isEditorNotification(item)
          );
      }
    },
    filteredReadNotifications() {
      switch (this.currentTab) {
        case Tabs.all:
        default:
          return this.readNotifications.items;
        case Tabs.reader:
          return this.readNotifications.items.filter((item) => this.isReaderNotification(item));
        case Tabs.editor:
          return this.readNotifications.items.filter((item) => this.isEditorNotification(item));
        case Tabs.another:
          return this.readNotifications.items.filter(
            (item) => !this.isReaderNotification(item) && !this.isEditorNotification(item)
          );
      }
    },
    isReaderNotification() {
      return (notification: NotificationFromNolaNovel) =>
        notification.type === NotificationDataType.BOOKMARK ||
        notification.type === NotificationDataType.FOLLOW ||
        notification.type === NotificationDataType.REVIEW ||
        notification.type === NotificationDataType.COMMENT ||
        notification.type === NotificationDataType.COMMENT_REPLY ||
        notification.type === NotificationDataType.COMMENT_ACTIVITY ||
        notification.type === NotificationDataType.COMMENT_ACTIVITY_REPLY ||
        notification.type === NotificationDataType.FAVORITE_ACTIVITY;
    },
    isEditorNotification() {
      return (notification: NotificationFromNolaNovel) =>
        notification.type === NotificationDataType.COMPANY_FOOTPRINTS ||
        notification.type === NotificationDataType.COMPANY_FOOTPRINTS_EPISODE ||
        notification.type === NotificationDataType.COMPANY_FEATURED_NOVEL ||
        notification.type === NotificationDataType.COMPANY_BOOKMARK ||
        notification.type === NotificationDataType.ADMIN_PICKUP;
    },
  },
  methods: {
    onClickTab(name) {
      this.currentTab = name;

      // NOTE: 通知の取得が完了していない場合、強制的にローディングを実施する。
      //
      // InfiniteLoadingはスクロールされることでHandlerが呼び出されるが、
      // アイテムが少ない場合にはスクロールが発生しないため、タブ切り替え時に強制的にローディングを実施する。
      if (!this.isLoading && !(this.isCompleteUnread && this.isCompleteRead)) {
        (this.$refs.infiniteLoading as InfiniteLoading).stateChanger.reset();
      }
    },
    async infiniteHandler($state: StateChanger) {
      if (this.isLoading) {
        (this.$refs.infiniteLoading as InfiniteLoading).stateChanger.reset();
        return;
      }

      this.isLoading = true;

      try {
        // 未読通知の取得
        if (!this.isCompleteUnread) {
          await this.infiniteUnreadHandler($state);
          return;
        }

        // 既読通知の取得
        await this.infiniteReadHandler($state);
      } finally {
        this.isLoading = false;
      }
    },
    async infiniteUnreadHandler($state: StateChanger) {
      if (this.unreadNotifications.lastKey) {
        await this.$store.dispatch("nolaNovelModule/loadNotifications", { isRead: false });
        $state.loaded();
      } else {
        // 未読を取得し切ったら既読通知を取得する
        this.isCompleteUnread = true;
        $state.loaded();

        // 3秒後に全通知を既読にする
        this.timeout = setTimeout(this.readAllNotifications, 3000);
      }
    },
    async infiniteReadHandler($state: StateChanger) {
      if (!this.isCompleteFirstRead) {
        await this.$store.dispatch("nolaNovelModule/fetchNotifications", { isRead: true });
        this.isCompleteFirstRead = true;
        $state.loaded();
      } else if (this.readNotifications.lastKey) {
        await this.$store.dispatch("nolaNovelModule/loadNotifications", { isRead: true });
        $state.loaded();
      } else {
        $state.complete();
        this.isCompleteRead = true;
      }
    },
    readAllNotifications() {
      if (this.unreadNotifications.items.length > 0) {
        this.$store.dispatch("nolaNovelModule/readAllNotifications");
      }
    },
  },
});

interface Props {}

interface Data {
  tabs: any;
  currentTab: TabType;
  timeout: any;
  isCompleteUnread: boolean;
  isCompleteFirstRead: boolean;
  isCompleteRead: boolean;
  isLoading: boolean;
}

interface Computed {
  isActiveTab(name: TabType): boolean;
  isNoData: boolean;
  unreadNotifications: NotificationsFromNolaNovel;
  readNotifications: NotificationsFromNolaNovel;
  filteredUnreadNotifications: NotificationFromNolaNovel[];
  filteredReadNotifications: NotificationFromNolaNovel[];
  isReaderNotification(notification: NotificationFromNolaNovel): boolean;
  isEditorNotification(notification: NotificationFromNolaNovel): boolean;
}

interface Methods {
  onClickTab(name: TabType): void;
  infiniteHandler($state: StateChanger): Promise<void>;
  infiniteUnreadHandler($state: StateChanger): Promise<void>;
  infiniteReadHandler($state: StateChanger): Promise<void>;
  readAllNotifications(): void;
}
