/**
 * Remote Configに設定しているパラメーターのうち、アプリ側で使用したいパラメーターのキー
 */
export const remoteConfigKeys = {
  company_post_button_ab_key: 'company_post_button_ab_key'
}

/**
 * remoteConfigKeysに設定したキーのデフォルト値を設定
 * このデフォルト値は、Remote Configのfetch/active/getValueに失敗した場合に使用される
 */
export const remoteConfigParams: Record<string, any> = {
  [remoteConfigKeys.company_post_button_ab_key]: 'A'
}